import photo01 from '@/assets/messageDemo/demo-photo01.jpg';
import photo02 from '@/assets/messageDemo/demo-photo02.jpg';
import photo03 from '@/assets/messageDemo/demo-photo03.jpg';
import photo04 from '@/assets/messageDemo/demo-photo04.jpg';
import photo05 from '@/assets/messageDemo/demo-photo05.jpg';
import photo06 from '@/assets/messageDemo/demo-photo06.jpg';
import photo07 from '@/assets/messageDemo/demo-photo07.jpg';
import photo08 from '@/assets/messageDemo/demo-photo08.jpg';
import photo09 from '@/assets/messageDemo/demo-photo09.jpg';
import photo10 from '@/assets/messageDemo/demo-photo10.jpg';
import photo11 from '@/assets/messageDemo/demo-photo11.jpg';
//照片
export const jsonData = {
  data: [
    [
      '2023-08-02',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9dd',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo03.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo03.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo03.webp',
          thumb_key: 'demo/images/famikeep-demo-photo03.webp',
          thumb_url: photo03,
          date: 1691013914,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9dc',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo02.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo02.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo02.webp',
          thumb_key: 'demo/images/famikeep-demo-photo02.webp',
          thumb_url: photo02,
          date: 1691005229,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9db',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo01.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo01.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo01.webp',
          thumb_key: 'demo/images/famikeep-demo-photo01.webp',
          thumb_url: photo01,
          date: 1691004044,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
      ],
    ],
    [
      '2023-08-01',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e1',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo07.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo07.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo07.webp',
          thumb_key: 'demo/images/famikeep-demo-photo07.webp',
          thumb_url: photo07,
          date: 1690913439,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9e0',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo06.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo06.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo06.webp',
          thumb_key: 'demo/images/famikeep-demo-photo06.webp',
          thumb_url: photo06,
          date: 1690899955,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9df',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo05.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo05.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo05.webp',
          thumb_key: 'demo/images/famikeep-demo-photo05.webp',
          thumb_url: photo05,
          date: 1690892109,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9de',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo04.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo04.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo04.webp',
          thumb_key: 'demo/images/famikeep-demo-photo04.webp',
          thumb_url: photo04,
          date: 1690889420,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
      ],
    ],
    [
      '2023-07-25',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e2',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo08.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo08.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo08.webp',
          thumb_key: 'demo/images/famikeep-demo-photo08.webp',
          thumb_url: photo08,
          date: 1690318224,
          dateYMD: '2023-07-25',
          isSelect: false,
        },
      ],
    ],
    [
      '2022-10-30',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e5',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo11.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo11.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo11.webp',
          thumb_key: 'demo/images/famikeep-demo-photo11.webp',
          thumb_url: photo11,
          date: 1667184655,
          dateYMD: '2022-10-30',
          isSelect: false,
        },
      ],
    ],
    [
      '2022-07-23',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e4',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo10.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo10.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo10.webp',
          thumb_key: 'demo/images/famikeep-demo-photo10.webp',
          thumb_url: photo10,
          date: 1658613320,
          dateYMD: '2022-07-23',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9e3',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo09.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo09.webp',
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo09.webp',
          thumb_key: 'demo/images/famikeep-demo-photo09.webp',
          thumb_url: photo09,
          date: 1658594130,
          dateYMD: '2022-07-23',
          isSelect: false,
        },
      ],
    ],
  ],
  update_time: 1697596039,
};
export const previewImgListJson = [
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo03.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo02.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo01.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo07.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo06.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo05.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo04.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo08.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo11.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo10.webp',
  'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo09.webp',
];
//文件夹
export const jsonData1 = {
  code: 200,
  message: 'OK',
  data: {
    elements: [
      {
        type: 2,
        name: 'Cute',
        count: 3,
      },
      {
        type: 2,
        name: 'Fun',
        count: 6,
      },
      {
        type: 2,
        name: 'nature',
        count: 2,
      },
    ],
    update_time: 1697596039,
  },
};
//第一个文件夹的照片
export const jsonData2 = {
  data: [
    [
      '2023-08-01',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e1',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo07.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo07.webp',
          thumb_key: 'demo/images/famikeep-demo-photo07.webp',
          thumb_url: photo07,
          download_url:
            'https://my.parentalguard.com/oss/proxy/demo%2Fimages%2Ffamikeep-demo-photo07.webp',
          date: 1690913439,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
      ],
    ],
    [
      '2023-07-25',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e2',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo08.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo08.webp',
          thumb_key: 'demo/images/famikeep-demo-photo08.webp',
          thumb_url: photo08,
          date: 1690318224,
          dateYMD: '2023-07-25',
          isSelect: false,
        },
      ],
    ],
    [
      '2022-07-23',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e3',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo09.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo09.webp',
          thumb_key: 'demo/images/famikeep-demo-photo09.webp',
          thumb_url: photo09,
          date: 1658594130,
          dateYMD: '2022-07-23',
          isSelect: false,
        },
      ],
    ],
  ],
};
//第二个文件夹的照片
export const jsonData3 = {
  data: [
    [
      '2023-08-02',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9dd',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo03.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo03.webp',
          thumb_key: 'demo/images/famikeep-demo-photo03.webp',
          thumb_url: photo03,
          date: 1691013914,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9dc',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo02.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo02.webp',
          thumb_key: 'demo/images/famikeep-demo-photo02.webp',
          thumb_url: photo02,
          date: 1691005229,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9db',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo01.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo01.webp',
          thumb_key: 'demo/images/famikeep-demo-photo01.webp',
          thumb_url: photo01,
          date: 1691004044,
          dateYMD: '2023-08-02',
          isSelect: false,
        },
      ],
    ],
    [
      '2023-08-01',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e0',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo06.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo06.webp',
          thumb_key: 'demo/images/famikeep-demo-photo06.webp',
          thumb_url: photo06,
          date: 1690899955,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9df',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo05.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo05.webp',
          thumb_key: 'demo/images/famikeep-demo-photo05.webp',
          thumb_url: photo05,
          date: 1690892109,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
        {
          id: '652f4287ed2d9a04c57ec9de',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo04.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo04.webp',
          thumb_key: 'demo/images/famikeep-demo-photo04.webp',
          thumb_url: photo04,
          date: 1690889420,
          dateYMD: '2023-08-01',
          isSelect: false,
        },
      ],
    ],
  ],
};
//第三个文件夹的照片
export const jsonData4 = {
  data: [
    [
      '2022-10-30',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e5',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo11.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo11.webp',
          thumb_key: 'demo/images/famikeep-demo-photo11.webp',
          thumb_url: photo11,
          date: 1667184655,
          dateYMD: '2022-10-30',
          isSelect: false,
        },
      ],
    ],
    [
      '2022-07-23',
      false,
      [
        {
          id: '652f4287ed2d9a04c57ec9e4',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo10.webp',
          origin_url:
            'https://parentalguard-prod.oss-ap-southeast-1.aliyuncs.com/demo%2Fimages%2Ffamikeep-demo-photo10.webp',
          thumb_key: 'demo/images/famikeep-demo-photo10.webp',
          thumb_url: photo10,
          date: 1658613320,
          dateYMD: '2022-07-23',
          isSelect: false,
        },
      ],
    ],
  ],
};
//ios的照片
export const jsonDataIos = {
  data: {
    code: 200,
    message: 'OK',
    data: {
      elements: [
        {
          id: '654075555d488a34ca0488c4',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo03.webp',
          origin_url: photo03,
          date: 1691013914,
        },
        {
          id: '654075555d488a34ca0488c3',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo02.webp',
          origin_url: photo02,
          date: 1691005229,
        },
        {
          id: '654075555d488a34ca0488c2',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo01.webp',
          origin_url: photo01,
          date: 1691004044,
        },
        {
          id: '654075555d488a34ca0488c8',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo07.webp',
          origin_url: photo07,
          date: 1690913439,
        },
        {
          id: '654075555d488a34ca0488c7',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo06.webp',
          origin_url: photo06,
          date: 1690899955,
        },
        {
          id: '654075555d488a34ca0488c6',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo05.webp',
          origin_url: photo05,
          date: 1690892109,
        },
        {
          id: '654075555d488a34ca0488c5',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo04.webp',
          origin_url: photo04,
          date: 1690889420,
        },
        {
          id: '654075555d488a34ca0488c9',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo08.webp',
          origin_url: photo08,
          date: 1690318224,
        },
        {
          id: '654075555d488a34ca0488cc',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo11.webp',
          origin_url: photo11,
          date: 1667184655,
        },
        {
          id: '654075555d488a34ca0488cb',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo10.webp',
          origin_url: photo10,
          date: 1658613320,
        },
        {
          id: '654075555d488a34ca0488ca',
          type: 1,
          oss_key: 'demo/images/famikeep-demo-photo09.webp',
          origin_url: photo09,
          date: 1658594130,
        },
      ],
      update_time: 1698723157,
      total: 11,
    },
  },
};
